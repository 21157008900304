import { createStore } from 'vuex'
import {getList} from "@/api/banner"

export default createStore({
  state: {
    activeMenu:'/',
    subActiveMenu:[],//选中的子菜单
    bannerList:[],
  },
  getters: {
    getActiveMenu(state){
      return state.activeMenu;
    },
    getSubActiveMenu(state){
      return state.subActiveMenu;
    },
    getBanner(state,name){
      return state.bannerList.filter((val:any)=>{
        return val.module===name;
      })
    }
  },
  mutations: {
    setActiveMenu(state,data){
      state.activeMenu = data;
    },
    setSubActiveMenu(state,data){
      state.subActiveMenu = data;
    },
    setBannerList(state,data){
      state.bannerList = data;
    }
  },
  actions: {
    getBannerList:async (state:any)=>{
      try {
        let res:any = await getList();
        if(res.errcode==0){
          state.commit('setBannerList',res.data);
        }
      } catch (error) {
        console.log(error);
        
      }
      
    }
  },
  modules: {
  }
})
