<template>
    <el-backtop class="fixed_con" :bottom="100">
        <div @click.stop="handleContactUs">
            <el-image :src="telImg" />
            <span>联系我们</span>
        </div>
        <div>
            <el-image :src="gotopImg" />
            <span>回到顶部</span>
        </div>
    </el-backtop>
</template>
<script setup>
import { ref } from 'vue';
import telImg from '@/assets/img/link_us.png'
import gotopImg from '@/assets/img/back_top.png'
import { useRouter } from 'vue-router'
import { useStore } from "vuex"



const store = useStore();
const router = useRouter()
// 联系我们
const handleContactUs = () => {
    console.log(11111);
    router.push({
        path: "/link",
    });
    store.commit('setActiveMenu', '/relevant');
}
</script>


<style lang="scss">
.fixed_con {
    border-radius: 0!important;
    width: 97px!important;
    height: 214px!important;
    flex-direction: column!important;
    justify-content: space-around!important;
    align-items: center;
    box-sizing: border-box;
    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        color: #6d6d6d;

        span {
            font-size: 12px;
            color: #6D6D6D;
            line-height: 17px;
            margin-top: 7px;
        }
    }
}
</style>