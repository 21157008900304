import {post,get,postUploadFile,deleteFn} from '@/utils/axios/axios'
let prefix:String =  process.env.VUE_APP_SRC;

export function getList(data:object={}){
  return get(prefix+'/homepage/banner',data);
}

// 获取产品描述
export function getProdDesc(data:object={}){
  return post(prefix+'/productinfo',data,{});
}