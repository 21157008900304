<template>
    <div class="footers cboxFlexCenter">
        <div class='cmain footers_content'>
            <p class='nav text'>
                <span class="ccursor" @click="goto({path:'/company_intro',activeMenu:'/relevant'})">关于中安</span>
                <span class="line">|</span>
                <span class="ccursor" @click="goto({path:'/product_overview',activeMenu:'/product'})">产品中心</span>
                <span class="line">|</span>
                <span class="ccursor" @click="goto({path:'/universal_solution',activeMenu:'/plan'})">解决方案</span>
                <span class="line">|</span>
                <span class="ccursor" @click="goto({path:'/link',activeMenu:'/relevant'})">联系我们</span>
            </p>
            <!-- <p class='special company text'>版权所有 © 2023 陕西中安数联信息技术有限公司 </p> -->
            <p class='text bottom_text'>
                <!-- <a href=\"https://beian.mps.gov.cn/#/query/webSearch?code=61019002002601\" rel=\"noreferrer\" target=\"_blank\">陕公网安备61019002002601</a> -->
                <a href="https://beian.mps.gov.cn/#/query/webSearch?code=61019002002601" rel="noreferrer" target="_blank"
                    class="gong-an">
                    <img src="@/assets/icon-gongan.png" />
                    陕公网安备61019002002601
                </a>
                <span class="ccursor" @click="go">陕ICP备2023011076号-1</span>
            </p>
            <div class="code">
                <img src="@/assets/img/code.png" />
                <div>关注中安数联公众号</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    reactive,
    ref,
    watch,
    computed,
    onMounted
} from 'vue';
import {
    useRoute,
    useRouter
} from 'vue-router';
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();
let menu = [
    { name: '首页', path: '/', },
    { name: '产品中心', path: '/product', },
    { name: '解决方案', path: '/plan', },
    { name: '客户支持', path: '/customer', },
    { name: '关于中安', path: '/relevant', },
    { name: '联系我们', path: '/contact', },
    { name: '新闻中心', path: '/newsList', },
]

let goto = ({path,activeMenu}) => {
    router.push(path);
    store.commit('setActiveMenu', activeMenu);
}
let go = (idx) => {
    window.open(`https://beian.miit.gov.cn`, "_blank");
}
</script>
<style scoped lang="scss">
.footers {
    width: 100%;
    background-color: #01081A;
    font-size: 12px;
    color: #999999;
    line-height: 45px;
    height: 217px;
    text-align: center;
    .footers_content{
        margin-top: 10px;
        position: relative;
    }

    .line {
        padding: 0 10px;
    }

    .text {
        line-height: 20px;
        font-size: 18px;
        .gong-an {
            color: #999999;
            margin-right: 10px;

            img {
                width: 12px;
                height: 12px;
            }
        }
    }

    .special {
        padding-top: 10px;
    }

    .code {
        position: absolute;
        right: 80px;
        top: -20px;
        img {
            width: 114px;
            height: 114px;
        }
        div{
            margin-top: -20px;
        }
    }
    .bottom_text{
        margin-top: 48px;
        font-size: 14px;

    }
}</style>