import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from "element-plus/es/locale/lang/zh-cn"
import './assets/font/iconfont.css'
window.addEventListener('beforeunload',()=>{
    sessionStorage.setItem('activeMenu',JSON.stringify(store.state))
})
let data = sessionStorage.getItem("activeMenu") || "";
if(data){
    store.replaceState(JSON.parse(data));
}

createApp(App)
.use(store)
.use(router)
.use(ElementPlus,{locale:zhCn})
.mount('#app')
