import axios from "axios";
// import { store } from '@/store/index'

export const axiosInstance = axios.create({
  // baseURL: "/api",
  timeout:10000 
});
import { ElMessage } from 'element-plus'

// request拦截器
axiosInstance.interceptors.request.use(
  (requestInfo:any) => {
    if (requestInfo.headers) {
      let token = localStorage.getItem("token");

      requestInfo.headers["Authorization"] = token ? "Bearer " + token : "";
      requestInfo.headers["Content-type"] = "application/json;charset-UTF-8";
      
      return requestInfo;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response 拦截器
axiosInstance.interceptors.response.use((response:any) => {
  let res = response.data;

  // if (res.errcode === 0) {
    
  // }
  //  else if (res.errcode === 10001) {
  //   ElMessage.error("未登录或者登录过期，请登录!");
  // } else if (res.errcode === 10002) {
  //   ElMessage.error("权限不足!");
  // } else if (res.errcode === 10003) {
  //   ElMessage.error("token过期，请重新登录！");
  //   store.commit("authStore/reset");
  // } else {
  //   ElMessage.error(res.errormsg);
  // }

  return res; 
});


export function post(url:string,data:object,params:object){
  return axiosInstance({
    url,
    method: "post",
    data,
    params
  });
}

export function get(url:string,params:object){
  return axiosInstance({
    url,
    method: "get",
    params
  });
};

export function deleteFn(url:string,data:object,params:object){
  return axiosInstance({
    url,
    method: "delete",
    params,
    data,
  });
};

export const postUploadFile = (url:string, data:object) => {
  return axiosInstance({
    method: 'post',
    url,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};