<template>
    <div :class="['heads', isNew ? 'headsNew' : 'headsWhite']">
        <div class='cmain'>
            <el-row class="header">
                <el-col :span='8'>
                    <img class='cimg logo' style='width:111px;'
                        :src="isNew ? require('../assets/logoNew.png') : require('../assets/logo.png')" alt='logo'>
                </el-col>
                <el-col class='nav' :span='16'>
                    <el-menu :default-active='activeMenuIdx' mode='horizontal' :active-text-color="isNew ? '#fff' : 'black'"
                        :text-color="isNew ? '#fff' : 'black'" :background-color="isNew ? '#022746' : '#fff'"
                        :ellipsis="false" @mouseleave="setThirdMenu">
                        <template v-for="item in menu">
                            <el-menu-item :index='item.path' v-if="item.visible !== false && (!item?.children?.length)" @click="jump({ path: item.path })">
                                {{ item.name }}
                            </el-menu-item>
                            <!-- 二级菜单 -->
                            <el-sub-menu :index='item.path' v-if="item.visible !== false && item.children?.length > 0" placement="placement"
                                popper-class="sub-menu-style" :teleported="false" :popper-offset="20">
                                <template #title>
                                    <span :class="['sub-title', activeMenuIdx === item.path ? 'sub-is-active' : '']" @click="jump({ path: item.redirect,firstLevel:item.path  })">{{ item.name }}</span>
                                </template>
                                <div class="df son-menu">
                                    <div class="left">
                                        <div v-for="sub_item in item.children"
                                            @click="jump({ path: sub_item.path, firstLevel: item.path, children: sub_item?.children })"
                                            :class="['sub-label', subMenuActive.includes(sub_item.path) && 'sub_active']"
                                            @mouseenter="getThirdMenu(sub_item?.children, sub_item.path)">
                                            {{ sub_item.name }}
                                        </div>
                                    </div>
                                    <div class="right" v-if="thirdMenu">
                                        <div v-for="third_item in thirdMenu"
                                            @click="jump({ path: third_item.path, firstLevel: item.path, type: 'third' })"
                                            :class="['third-label']">
                                            <span :class="subMenuActive.includes(third_item.path) && 'sub_active'">
                                                {{ third_item.name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </el-sub-menu>
                        </template>
                    </el-menu>
                </el-col>
            </el-row>
        </div>
        <el-dialog v-model="dialog" width="30%" class="dialog">
            <div class='cont'>
                <span class="label">合同编号或SN号</span>
                <el-input v-model.trim="SNData" max="50" placeholder="请输入合同编号或SN号" size="small" clearable />
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button class="btnZaText" @click="dialog = false" size="small">取 消</el-button>
                    <el-button class="btnZaPrimary" type="primary" size="small" @click="goTo">确 认 </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
  
<script setup>
import { ElMessage } from 'element-plus'
import {
    reactive,
    ref,
    watch,
    computed,
    onMounted
} from 'vue';
import {
    useRoute,
    useRouter
} from 'vue-router';
import { mapState, useStore, mapMutations, mapActions } from "vuex";

const store = useStore();
const router = useRouter();
const route = useRoute();

const menu = [
    { name: '首页', path: '/', },
    {
        name: '产品中心', path: '/product',redirect:'/product_overview',
        children: [
            { name: 'TeraBackup备份与恢复系统', path: '/backup_recovery_system' },
            { name: 'TeraBackup备份一体机', path: '/backup_recovery' },
            { name: 'TeraCloud云灾备服务平台', path: '/service_platform' },
            { name: 'CSDUDRO应用级容灾平台', path: '/takeover_platform' },
            { name: 'TCMP云迁移平台', path: '/migration_platform' },
        ]
    },
    {
        name: '解决方案', path: '/plan',redirect:'/universal_solution',
        children: [
            { name: '通用解决方案', path: '/universal_solution' },
            {
                name: '行业解决方案', path: '/industry_solution',
                children: [
                    { name: '数字政务', path: '/digital_government' },
                    { name: '信创云', path: '/xin_chuang_cloud' },
                    { name: '金融财经', path: '/finance_economics' },
                    { name: '智慧医疗', path: '/smart_healthcare' },
                    { name: '智慧教育', path: '/smart_education' },
                    { name: '军工行业', path: '/military_industry' },
                    { name: '制造业', path: '/manufacture_industry' },
                    { name: '能源行业', path: '/energy_industry' },
                ]
            }
        ]
    },
    {
        name: '服务与支持', path: '/service_support',redirect:'/base_service',
        children: [
            { name: '基础服务', path: '/base_service' },
            { name: '在线自助服务', path: '/online_service' },
            { name: '资料下载', path: '/resource' },
        ]
    },
    { name: '合作生态', path: '/collaborative_ecosystem', },
    {
        name: '关于中安', path: '/relevant',redirect:'/company_intro',
        children: [
            { name: '公司介绍', path: '/company_intro' },
            { name: '联系我们', path: '/link' },
            { name: '新闻动态', path: '/newsList' }
        ]
    }
]
const activeMenuIdx = computed(() => {
    return store.state.activeMenu === '' ? '/' : store.state.activeMenu;
})
const blackNav = ref([])
// 递归菜单获取path集合--显示透明底导航栏
const reverse = (tree) => {
    tree.forEach(v => {
        blackNav.value.push(v.path)
        if (v.children?.length) {
            reverse(v.children)
        }
    });
}
const isNew = computed(() => {
    if (store.getters.getActiveMenu) {
        reverse(menu)
        return blackNav.value.includes(store.getters.getActiveMenu);
    }
    return false;
})
const subMenuActive = computed(() => {
    return store.getters.getSubActiveMenu || []
})
const thirdMenu = ref()

// router.push(routeObj.idx);
const jump = ({ path, firstLevel, children, type }) => {
    if (children) {
        return false
    }
    // 一级路由跳转
    store.commit('setActiveMenu', firstLevel ? firstLevel : path);
    router.push(path);
}
const getThirdMenu = (children, path) => {
    thirdMenu.value = children || null
}
const setThirdMenu = () => {
    thirdMenu.value = null
}
const changeMenu = (path) => {
    if (idx == "2-1") {
        // SNData.value = "";
        // dialog.value = true;
        window.open(`https://support.csdun.com`, "_self");
    } else if (idx == "2-2") {
        window.open("https://know.csdun.com", "_self");
    } else {
        store.commit('setActiveMenu', path);
        router.push(path);
    }
}

let goTo = () => {
    if (SNData.value === "") {
        ElMessage({
            showClose: true,
            message: '请先填写合同编号或SN号！',
            type: 'warning',
        })
    } else {
        window.open(`https://support.csdun.com?workOrder=${SNData.value}`, "_self");
    }
}
// 订单，弹出框
var dialog = ref(false);
let SNData = ref('');


//banner获取
store.dispatch('getBannerList')
</script>
<style scoped lang="scss">
.heads {
    position: absolute;
    z-index: 20;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);

    .logo {
        width: 145px !important;
    }

    :deep(.dialog) {
        .el-dialog__header {
            display: none;
        }

        .cont {
            display: flex;
            align-items: center;

            .label {
                width: 115px;
            }

            .el-input {
                flex: 1;
            }
        }

    }
}

.header {
    display: flex;
    align-items: center;
    height: 39px;
    margin-top: 12px;

    .el-menu,
    .el-sub-menu {
        background-color: transparent;
        height: 100%;
    }

    .el-menu--horizontal {
        display: flex;
        justify-content: flex-end;
        border-bottom: none;
    }

    .el-menu--horizontal .el-menu-item.is-active {
        text-decoration: none;
        border-bottom-color: var(--el-menu-active-color);
        font-weight: bold;
    }

    .el-menu-item {
        background-color: transparent !important;
        padding: 0 5px;
        line-height: 50px;
        margin-left: 80px;
        font-size: 20px !important;
        height: 100%;
    }

    :deep(.el-sub-menu__title) {
        background-color: transparent !important;
        border-bottom-color: transparent !important;
    }

    .sub-is-active {
        text-decoration: none;
        border-bottom-color: var(--el-menu-active-color) !important;
        font-weight: bold;
    }

    .sub-title {
        border-bottom: 3px solid transparent;
    }

    ::v-deep .sub-menu-style {
        opacity: 0.85;
        .el-menu--popup {
            background: rgba(255, 255, 255, 1);
            border-radius: 6px;
            padding: 22px 26px;

            &:before {
                content: "";
                width: 0;
                height: 0;
                position: absolute;
                top: -10px;
                left: 35px;
                border-right: solid 12px transparent;
                border-left: solid 12px transparent;
                border-bottom: solid 10px #fff;
            }
        }

        .sub-label {
            font-size: 18px !important;
            color: #020818;
            padding-bottom: 6px;
            border-bottom: 2px solid transparent;
            line-height: 25px;
            cursor: pointer;

            &:not(:last-child) {
                margin-bottom: 26px;
            }

            &:hover {
                color: #C60912;
            }
        }



        .sub_active {
            display: inline-block;
            color: #180402;
            color: #C60912 !important;
        }

        .third-label {
            width: 100%;
            font-size: 18px;
            color: #424242;
            line-height: 35px;
            cursor: pointer;
            text-align: center;

            &:not(:last-child) {
                margin-bottom: 18px;
            }

            &:hover {
                color: #C60912 !important;
            }
        }

        .son-menu {
            justify-content: flex-start;
            align-items: flex-start;

            .left {
                height: 100%;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
            }

            .right {
                width: 140px;
                margin-left: 20px;
                border-left: 1px solid #E8E8E8;
                box-sizing: border-box;
            }
        }

    }

}

.headsNew .el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 3px solid var(--el-menu-active-color);
}

.headsNew {
    background: none;
}

// 新闻详情等页面所用到的白色背景导航栏
.headsWhite {
    height: 76px;
    background: #FFFFFF;
    box-shadow: 0px 2px 11px 0px rgba(172, 172, 172, 0.5);
}

.nav {
    height: 100%;
}
</style>
<style lang="scss">
.el-popper .el-menu--popup {
    min-width: 150px;
}

.header {
    .el-sub-menu {
        .el-sub-menu__icon-arrow {
            display: none !important;
        }
    }

    .el-sub-menu>.el-sub-menu__title {
        display: block;
        padding: 0 5px;
        line-height: 50px;
        margin-left: 80px;
        font-size: 20px !important;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}
</style>