<template>
    <div class="par_container" ref="par_container">
        <heads></heads>
        <router-view />
        <footers></footers>
        <goTop></goTop>
        
    </div>
</template>
<script setup>
import { reactive, ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import heads from "@/components/heads.vue"
import footers from "@/components/footers.vue"
import goTop from '@components/home/goTop.vue'


onMounted(() => {

})
const audit = (record) => {

}
</script>
<style lang="scss">
@import '@/utils/css/common.scss';

#app {
    position: relative;
    //   min-height:900px;
    //   padding-bottom:217px;
    font-family: 'Noto Sans SC';
}

@font-face {
    font-family: 'Noto Sans SC';
    font-style: normal;
    font-weight: normal;
    src: url('@/assets/font/SourceHanSansCN-Medium.ttf') format("truetype");
}

.par_container {
    width: 100%;
    position: relative;
    // overflow-x: hidden;
    // height: 100vh;
    // overflow-y: auto;
}

.par_container::-webkit-scrollbar {
    display: none;
}

// 美化滚动条
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    width: 6px;
    background: rgba(#101F1C, 0.1);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, .5);
    background-clip: padding-box;
    min-height: 28px;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
    transition: background-color .3s;
    cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(144, 147, 153, .3);
}
</style>
