import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/home.vue'
import store from "../store/index";


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home.vue')
  },

  // 通用解决方案
  {
    path: '/universal_solution',
    name: 'universal_solution',
    meta: {
      activeMenu: ['/universal_solution']//二级菜单高亮
    },
    component: () => import('@/views/plan/plan.vue')
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import('@/views/customer.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact.vue')
  },

  {
    path: '/newsMsg',
    name: 'newsMsg',
    component: () => import('@/views/newsMsg.vue')
  },
  // 服务与支持
  {
    path: '/base_service',
    name: 'base_service',
    meta: {
      activeMenu: ['/base_service']//二级菜单高亮
    },
    component: () => import('@/views/service_support/base_service.vue')
  },
  {
    path: '/online_service',
    name: 'online_service',
    meta: {
      activeMenu: ['/online_service']//二级菜单高亮
    },
    component: () => import('@/views/service_support/online_service.vue')
  },
  // 在线自助--认证证书查询
  {
    path: '/attestation',
    name: 'attestation',
    meta: {
      activeMenu: ['/online_service']//二级菜单高亮
    },
    component: () => import('@/views/service_support/attestation.vue')
  },
  // 资料下载
  {
    path: '/resource',
    name: 'resource',
    meta: {
      activeMenu: ['/resource']//二级菜单高亮
    },
    component: () => import('@/views/service_support/resource.vue')
  },
  // 合作生态
  {
    path: '/collaborative_ecosystem',
    name: 'collaborative_ecosystem',
    component: () => import('@/views/collaborative_ecosystem.vue')
  },
  // 关于中安-公司介绍
  {
    path: '/company_intro',
    name: 'company_intro',
    meta: {
      activeMenu: ['/company_intro']//二级菜单高亮
    },
    component: () => import('@/views/relevant/company_intro.vue')
  },
  // 关于中安-联系我们
  {
    path: '/link',
    name: 'link',
    meta: {
      activeMenu: ['/link']//二级菜单高亮
    },
    component: () => import('@/views/relevant/link.vue')
  },
  // 关于中安-新闻动态
  {
    path: '/newsList',
    name: 'newsList',
    meta: {
      activeMenu: ['/newsList']//二级菜单高亮
    },
    component: () => import('@/views/relevant/newsList.vue')
  },
  // 产品概览
  {
    path: '/product_overview',
    name: 'product_overview',
    meta: {
      activeMenu: ['/product_overview']//二级菜单高亮
    },
    component: () => import('@/views/product/product.vue')
  },
  // TeraBackup备份与恢复系统
  {
    path: '/backup_recovery_system',
    name: 'backup_recovery_system',
    meta: {
      activeMenu: ['/backup_recovery_system']//二级菜单高亮
    },
    component: () => import('@/views/product/backup_recovery_system.vue')
  },
  // TeraBackup备份一体机
  {
    path: '/backup_recovery',
    name: 'backup_recovery',
    meta: {
      activeMenu: ['/backup_recovery']//二级菜单高亮
    },
    component: () => import('@/views/product/backup_recovery.vue')
  },
  // TeraCloud云灾备服务平台
  {
    path: '/service_platform',
    name: 'service_platform',
    meta: {
      activeMenu: ['/service_platform']//二级菜单高亮
    },
    component: () => import('@/views/product/service_platform.vue')
  },
  // TDRP业务应急接管平台
  {
    path: '/takeover_platform',
    name: 'takeover_platform',
    meta: {
      activeMenu: ['/takeover_platform']//二级菜单高亮
    },
    component: () => import('@/views/product/takeover_platform.vue')
  },
  // TCMP云迁移平台
  {
    path: '/migration_platform',
    name: 'migration_platform',
    meta: {
      activeMenu: ['/migration_platform']//二级菜单高亮
    },
    component: () => import('@/views/product/migration_platform.vue')
  },
  // 数字政务
  {
    path: '/digital_government',
    name: 'digital_government',
    meta: {
      activeMenu: ['/industry_solution', '/digital_government']//二级菜单高亮
    },
    component: () => import('@/views/industry_solutions/digital_government.vue')
  },
  // 信创云
  {
    path: '/xin_chuang_cloud',
    name: 'xin_chuang_cloud',
    meta: {
      activeMenu: ['/industry_solution', '/xin_chuang_cloud']//二级菜单高亮
    },
    component: () => import('@/views/industry_solutions/xin_chuang_cloud.vue')
  },
  // 金融财经
  {
    path: '/finance_economics',
    name: 'finance_economics',
    meta: {
      activeMenu: ['/industry_solution', '/finance_economics']//二级菜单高亮
    },
    component: () => import('@/views/industry_solutions/finance_economics.vue')
  },
  // 智慧医疗
  {
    path: '/smart_healthcare',
    name: 'smart_healthcare',
    meta: {
      activeMenu: ['/industry_solution', '/smart_healthcare']//二级菜单高亮
    },
    component: () => import('@/views/industry_solutions/smart_healthcare.vue')
  },
  // 智慧教育
  {
    path: '/smart_education',
    name: 'smart_education',
    meta: {
      activeMenu: ['/industry_solution', '/smart_education']//二级菜单高亮
    },
    component: () => import('@/views/industry_solutions/smart_education.vue')
  },
  // 军工行业
  {
    path: '/military_industry',
    name: 'military_industry',
    meta: {
      activeMenu: ['/industry_solution', '/military_industry']//二级菜单高亮
    },
    component: () => import('@/views/industry_solutions/military_industry.vue')
  },
  // 制造业
  {
    path: '/manufacture_industry',
    name: 'manufacture_industry',
    meta: {
      activeMenu: ['/industry_solution', '/manufacture_industry']//二级菜单高亮
    },
    component: () => import('@/views/industry_solutions/manufacture_industry.vue')
  },
  // 能源行业
  {
    path: '/energy_industry',
    name: 'energy_industry',
    meta: {
      activeMenu: ['/industry_solution', '/energy_industry']//二级菜单高亮
    },
    component: () => import('@/views/industry_solutions/energy_industry.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  window.scroll(0, 0);
  store.commit('setSubActiveMenu', to.meta?.activeMenu||null);
  next();
});
export default router
